





























































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import * as types from '@/store/account/types';
import VButton from '@/components/VButton.vue';
import ValidatedForm from '@/utilities/validation/validatedform';
import FormField from '@/utilities/validation/formfield';
import { goToVue3AppUrl } from '@/store/adapter';

@Component({
  components: { VButton },
})
export default class Register extends Vue {

  @Action('verify', { namespace: 'account' }) private verify!: types.VerifyAction;

  private goToVue3 = goToVue3AppUrl;
  private completed: boolean = false;
  private submitting: boolean = false;
  private passwordField = new FormField((value, dirty) => value.length > 5, '');
  private repeatField = new FormField((value, dirty) => value.length > 5 && value === this.passwordField.value, '');

  private activationForm = new ValidatedForm({
    firstName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    lastName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    password: this.passwordField,
    repeatPassword: this.repeatField,
    termsChecked: new FormField((value, dirty) => value === 'checked', 'unchecked'),
  });

  get activationFormValid() {
    return this.activationForm.fieldValidity();
  }

  private submit() {
    if (this.activationForm.formValid()) {
      this.submitting = true;
      this.verify({
        password: this.activationForm.fields.password.value,
        firstName: this.activationForm.fields.firstName.value,
        lastName: this.activationForm.fields.lastName.value,
        token: this.$route.params.verifyToken,
        uidb64: this.$route.params.uid,
      }).then((response: {}) => {
        this.completed = true;
      }).finally(() => {
        this.submitting = false;
        this.completed = true;
      });
    }
  }
}
